body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.login-container {
  width: 350px;
  padding: 40px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.login-title {
  text-align: center;
  margin-bottom: 40px;
}

.dashboard-content {
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 32px;
  margin: 16px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f0f0f0;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
}